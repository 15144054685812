import { ApolloError } from "@apollo/client"
import {
    ControlSettingFieldsFragment,
    DataFrameFieldsFragment,
    DataPointFieldsFragment,
    WellAlertFieldsFragment,
    WellControlObservation,
    WellControlObservationStateFieldsFragment,
    WellIdentificationFieldsFragment,
} from "../../../generated/graphql"
import { FunctionComponent } from "react"
import {
    WellAlertConcernUiDisplayType,
    WellStatusUiDisplayType,
} from "../../../types/wellQueryResponseTypes"
import { getWellControlSettingFragmentFieldsUnion } from "../../../util/ControlSettingUtil"
import styles from "./statusSideBar.module.scss"
import { Link } from "react-router-dom"
import Alert from "../../shared/alert"
import {
    convertSecondsToHoursAndMinutes,
    convertUnixMillisecondsToLocalDate_Chicago,
    convertUnixMillisecondsToLocalTime_Chicago,
} from "../../../util/datesAndTimes/datesAndTimes"
import { getFragmentData } from "../../../generated"
import { DATE_TIME_FIELDS_FRAGMENT } from "../../../graphql/fragments/dateTime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBoltLightning,
    faCircleInfo,
    faRouter,
    faSensorOn,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons"
import ToolTip_Popover from "../../shared/toolTip/toolTip_popover"

interface StatusSideBarI {
    loading: boolean
    error?: ApolloError
    wellIdentificationFragment: WellIdentificationFieldsFragment | undefined
    runTimeDaily: DataFrameFieldsFragment | undefined
    latestSpmDataPoint: DataPointFieldsFragment | undefined
    wellAlertFragment: WellAlertFieldsFragment | undefined | null
    observationFragment: WellControlObservationStateFieldsFragment | undefined
    controlSetting: ControlSettingFieldsFragment | undefined
}

const StatusSideBar: FunctionComponent<StatusSideBarI> = ({
    wellIdentificationFragment,
    runTimeDaily: runTimeDataFrame,
    latestSpmDataPoint,
    wellAlertFragment,
    observationFragment,
    controlSetting,
}) => {
    const runModeWellControlsLink = wellIdentificationFragment?.id ? (
        <Link
            style={{ color: "#1e88e5", textDecoration: "none" }}
            to={`controls/edit`}
            relative="path"
        >
            {getRunModeSideBarDisplay(controlSetting)}
        </Link>
    ) : (
        getRunModeSideBarDisplay(controlSetting)
    )

    const wellAlertType =
        wellAlertFragment?.__typename === "InfoAlert" ? "warning" : "danger"

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.header}>Current Data</div>
            <div className={styles.sidebarBodyContainer}>
                {wellAlertFragment && (
                    <div style={{ marginBottom: "16px" }}>
                        <Alert
                            message={getWellAlertToolTip(wellAlertFragment)}
                            useCustomLeftIcon={true}
                            alertType={wellAlertType}
                            isCloseable={false}
                        />
                    </div>
                )}

                <div className={styles.detailsCardContainer}>
                    <div className={styles.detailsCardLabel}>Well Status</div>
                    <div className={styles.detailsCardValue}>
                        {getWellStatus(observationFragment)}
                    </div>
                </div>
                <div className={styles.detailsCardContainer}>
                    <div className={styles.detailsCardLabel}>Run Mode</div>
                    <div className={styles.detailsCardValue}>
                        {runModeWellControlsLink}
                    </div>
                </div>
                <div className={styles.detailsCardContainer}>
                    <div className={styles.detailsCardLabel}>
                        Run time (24 hr)
                    </div>
                    <div className={styles.detailsCardValue}>
                        {getDisplayRunTime(runTimeDataFrame?.data[0][0])}
                    </div>
                </div>
                <div className={styles.detailsCardContainer}>
                    <div className={styles.detailsCardLabel}>S.P.M</div>
                    <div className={styles.detailsCardValue}>
                        {getDisplaySpmValue(latestSpmDataPoint?.value)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusSideBar

/** Utility functions to help parse query response into strings, numbers or components that can be displayed */
const getWellStatus = (
    observationFragment: WellControlObservationStateFieldsFragment | undefined
): WellStatusUiDisplayType | undefined => {
    if (observationFragment === undefined) return
    switch (observationFragment.state) {
        case WellControlObservation.Running:
            return "Running"
        case WellControlObservation.Stopped:
            return "Stopped"
        case WellControlObservation.Unknown:
            // well is linked and status is unknown
            return "Unknown"
        default:
            throw new Error(
                "getWellStatus in statusSideBar file - observationFragment did not return a known value for wellControlObservationState"
            )
    }
}

const getDisplaySpmValue = (
    spm: number | undefined | null
): React.ReactNode => {
    if (spm === null || spm === undefined) {
        return <> - </>
    } else {
        return spm.toFixed(1)
    }
}

const getDisplayRunTime = (
    runTime: number | undefined | null
): React.ReactNode => {
    if (runTime === null || runTime === undefined) {
        return <> - </>
    } else {
        const { hours, minutes } = convertSecondsToHoursAndMinutes(runTime)
        return (
            <>
                {hours}h {minutes}m
            </>
        )
    }
}

const getRunModeSideBarDisplay = (
    controlSetting: ControlSettingFieldsFragment | undefined
) => {
    if (!controlSetting) return undefined
    const { runMode } = getWellControlSettingFragmentFieldsUnion(controlSetting)
    return runMode
}

const getWellAlertToolTip = (
    wellAlert: WellAlertFieldsFragment | null | undefined
): React.ReactNode => {
    let fontAwesomeIcon: React.ReactNode
    let wellAlertConcern: WellAlertConcernUiDisplayType

    if (!wellAlert) {
        return <></>
    } else {
        switch (wellAlert.__typename) {
            case "CommunicationsAlert":
                fontAwesomeIcon = (
                    <FontAwesomeIcon
                        className={styles.fai}
                        icon={faRouter}
                        color="#F44336"
                    />
                )
                wellAlertConcern = "Lost Communication"
                break
            case "SensorAlert":
                fontAwesomeIcon = (
                    <FontAwesomeIcon
                        icon={faSensorOn}
                        className={styles.fai}
                        color="#F44336"
                    />
                )

                wellAlertConcern = "Lost Sensor Communication"
                break
            case "ControlAlert":
                fontAwesomeIcon = (
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        color="#F44336"
                        className={styles.fai}
                    />
                )
                wellAlertConcern = "Loss of Control"
                break
            case "PowerLossAlert":
                fontAwesomeIcon = (
                    <FontAwesomeIcon
                        icon={faBoltLightning}
                        color="#F44336"
                        className={styles.fai}
                    />
                )
                wellAlertConcern = "Loss of Power"
                break
            case "InfoAlert":
                fontAwesomeIcon = (
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        color="#90A4AE"
                        className={styles.fai}
                    />
                )
                wellAlertConcern = undefined
                break
            default:
                return <></>
        }
    }

    const alertOccuredAt = getFragmentData(
        DATE_TIME_FIELDS_FRAGMENT,
        wellAlert.occurredAt
    )

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ToolTip_Popover
                    content={<>{wellAlert.tooltip}</>}
                    triggerChildren={fontAwesomeIcon}
                    popover={{
                        removePopoverButtonStyling: true,
                        side: "right",
                    }}
                    tooltip={{ side: "right" }}
                />
                <div style={{ marginLeft: "8px" }}>
                    <div>{wellAlertConcern}</div>
                    <div>
                        {`${convertUnixMillisecondsToLocalDate_Chicago(
                            alertOccuredAt.unixMilliseconds,
                            {
                                month: "2-digit",
                                day: "2-digit",
                                year: "2-digit",
                            }
                        )} ${convertUnixMillisecondsToLocalTime_Chicago(
                            alertOccuredAt.unixMilliseconds,
                            {
                                hour: "numeric",
                                minute: "2-digit",
                                hour12: true,
                                timeZoneName: "short",
                            }
                        )}`}
                    </div>
                </div>
            </div>
        </>
    )
}
