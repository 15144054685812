import { getFragmentData } from "../../generated"
import {
    GetIndexWellsQuery,
    WellAlertFieldsFragment,
} from "../../generated/graphql"
import { WELL_ALERT_FIELDS_FRAGMENT } from "../../graphql/fragments/wellAlert"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../graphql/fragments/wellIdentification"
import {
    WellIndexTableCustomRowClassType,
    WellIndexTableDataI,
    WellIndexUrlToStateMappings,
} from "./tableTypes"
import { CONTROL_SETTING_FIELDS_FRAGMENT } from "../../graphql/fragments/wellControlSetting"
import { getWellControlSettingFragmentFieldsUnion } from "../../util/ControlSettingUtil"
import { DATA_POINT_FIELDS_FRAGMENT } from "../../graphql/fragments/dataPoint"
import { WELL_CONTROL_STATE_FIELDS_FRAGMENT } from "../../graphql/fragments/wellState"
import { DATA_FRAME_FIELDS_FRAGMENT } from "../../graphql/fragments/dataFrame"
import { wellObservationStateToDipslay } from "../../util/queryToDisplay/wellState"
import WellIndexBarChart from "./cell/wellIndexBarChart/wellIndexBarChart"
import { TableStateType } from "../table/tableTypes"
import { getTableStateFromUrlParams } from "../table/tableUtil"
import { ORGANIZATION_COLUMN_ID } from "./column"

const formatWellsData = (
    wellIndexData: GetIndexWellsQuery
): WellIndexTableDataI[] => {
    const wellIndexDataFormatted: WellIndexTableDataI[] = []

    if (wellIndexData.wells.edges) {
        const { edges } = wellIndexData.wells
        for (let i = 0; i < edges.length; i++) {
            const well = edges[i].node

            // well identification fields
            const {
                name: wellName,
                id: wellId,
                linked: wellLinkedStatus,
                organization: { name: organizationName },
            } = getFragmentData(WELL_IDENTIFICATION_FIELDS_FRAGMENT, well)

            // well alert fields
            const wellAlert = getFragmentData(
                WELL_ALERT_FIELDS_FRAGMENT,
                well.alert
            )
            const customTableRowClass = getWellConditionClass(wellAlert)

            // format data for unlinked wells
            if (!wellLinkedStatus) {
                wellIndexDataFormatted.push({
                    alert: wellAlert,
                    wellName: wellName,
                    wellStatus: "Waiting for Install",
                    id: wellId,
                    menu: <></>,
                    runMode: undefined,
                    runTime: undefined,
                    spm: undefined,
                    runTimeWeeklyTrends: <></>,
                    organization: organizationName,
                    customTableRowClass: customTableRowClass,
                    wellLinkedStatus: wellLinkedStatus,
                })
                continue
            }
            // If well is linked - format data
            // run mode
            const controlSetting = getFragmentData(
                CONTROL_SETTING_FIELDS_FRAGMENT,
                well.controlSetting
            )
            const controlSettingFragmentUnion =
                getWellControlSettingFragmentFieldsUnion(controlSetting)
            // run time
            const dailyRunTime = getFragmentData(
                DATA_FRAME_FIELDS_FRAGMENT,
                well.equipmentMetrics.dailyRunTime
            )
            // Note: if this value does not exist it is null in the response from the api
            // tanStack sorting does not seem to handle null values as expected.
            // I am observing null sorted before 0 values when the configuration is set to sort nullish values last.
            // setting this value to undefined resolves this issue
            const runTimeValue =
                typeof dailyRunTime.data[0][0] === "number"
                    ? dailyRunTime.data[0][0]
                    : undefined

            // spm
            const latestSpmDataPoint = getFragmentData(
                DATA_POINT_FIELDS_FRAGMENT,
                well.equipmentMetrics.latestSpm
            )
            const spmValue =
                typeof latestSpmDataPoint.value === "number"
                    ? latestSpmDataPoint.value
                    : undefined

            // well status
            const wellState = getFragmentData(
                WELL_CONTROL_STATE_FIELDS_FRAGMENT,
                well.state
            )
            const wellStatus = wellObservationStateToDipslay(wellState)
            // windowedRuntimeDataFrame
            const windowedRuntimeDataFrame = getFragmentData(
                DATA_FRAME_FIELDS_FRAGMENT,
                well.equipmentMetrics.windowedRunTime
            )

            // push onto formatted data array
            const formattedData: WellIndexTableDataI = {
                alert: wellAlert,
                wellName: wellName,
                id: wellId,
                runMode: controlSettingFragmentUnion.runMode,
                runTime: runTimeValue,
                spm: spmValue,
                wellStatus: wellStatus,
                runTimeWeeklyTrends: (
                    <WellIndexBarChart
                        dataFrame={windowedRuntimeDataFrame}
                        cssComplianceClass={customTableRowClass}
                    />
                ),
                menu: <></>,
                organization: organizationName,
                customTableRowClass: customTableRowClass,
                wellLinkedStatus: wellLinkedStatus,
            }
            wellIndexDataFormatted.push(formattedData)
        }
    }
    return wellIndexDataFormatted
}

const wellIndexPageSizeSelectorArray = [15, 25, 50]

const getWellConditionClass = (
    wellAlert: WellAlertFieldsFragment | null | undefined
): WellIndexTableCustomRowClassType => {
    if (!wellAlert) {
        return "CompliantClass"
    } else {
        switch (wellAlert.__typename) {
            case "CommunicationsAlert":
            case "SensorAlert":
            case "PowerLossAlert":
            case "ControlAlert":
                return "DivergentClass"
            case "InfoAlert":
                return "CompliantClass"
            default:
                throw new Error(`unknown alert type: ${wellAlert.__typename}`)
        }
    }
}

const wellIndexTableUrlMapping: WellIndexUrlToStateMappings = {
    [ORGANIZATION_COLUMN_ID]: (value, state) => {
        state.filter.push({ id: ORGANIZATION_COLUMN_ID, value })
    },
}

const getWellIndexStateFromUrlQueryParams = (
    searchParams: URLSearchParams,
    customMapping: WellIndexUrlToStateMappings
): TableStateType => {
    const defaultState: TableStateType = {
        pageIndex: 0,
        size: 15,
        sorting: [],
        search: "",
        filter: [],
    }

    return getTableStateFromUrlParams(
        searchParams,
        defaultState,
        wellIndexPageSizeSelectorArray,
        customMapping
    )
}

export {
    formatWellsData,
    wellIndexPageSizeSelectorArray,
    getWellIndexStateFromUrlQueryParams,
    wellIndexTableUrlMapping,
}
