import { capMaxSeconds } from "../../../../util/DataFrameHighChartSeriesUtil"

const updateIncomingRunTimeForWellIndexBarChart = (
    highChartsSeries: (number | null)[][]
) => {
    if (!highChartsSeries) return

    // Following function will mutate the original array
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(highChartsSeries)

    // get last 7 days, each day is a point
    const last7Days = updatedHighChartSeries_capMaxSeconds.slice(-7)

    return last7Days
}

export { updateIncomingRunTimeForWellIndexBarChart }
