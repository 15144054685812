import HighStock from "highcharts/highstock"
import { convertSecondsToHoursAndMinutes } from "../../../util/datesAndTimes/datesAndTimes"

/**
 * To enable spm configurations
 * - legend.labelFormatter - remove this snippet:  if (this.name === "Spm") return ""
 * - yAxis[0].opposite should be true
 * - yAxis[0].titleOffset should be 30
 * - uncomment yAxis[1]
 * - uncomment - series[1] in this config
 * - uncomment - series[1] in use effect in ./index
 * - update Chart instance - Chart.downloadXLS to work with spm see comment : To add spm
 */

const wellDetailsChartOptions: HighStock.Options = {
    boost: {
        enabled: true,
    },
    rangeSelector: {
        enabled: true,
        buttons: [
            {
                type: "week",
                count: 1,
                text: "1w",
            },
            {
                type: "month",
                count: 1,
                text: "1m",
            },
            {
                type: "month",
                count: 3,
                text: "3m",
            },
            {
                type: "year",
                count: 1,
                text: "1y",
            },
        ],
    },
    chart: {
        zooming: {
            mouseWheel: true,
        },
    },
    title: {
        text: "",
    },
    credits: {
        enabled: false,
    },
    accessibility: {
        enabled: false,
    },
    legend: {
        enabled: true,
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        reversed: true,
        labelFormatter: function () {
            if (this.name === "Spm") return ""
            return (
                '<span style="color: ' +
                this.color +
                '">' +
                this.name +
                "</span>"
            )
        },
    },
    yAxis: [
        {
            title: {
                text: "Hours",
                style: {
                    color: "#FF9800",
                    fontWeight: "400",
                },
                // offset: 30, // enable when opposite is turned back to true - after spm is added to well details chart
            },
            // opposite: true,
            opposite: false,
            labels: {
                enabled: true,
                style: {
                    color: "#FF9800",
                },
                align: "right",
                formatter: function () {
                    if (typeof this.value === "number") {
                        const { hours } = convertSecondsToHoursAndMinutes(
                            this.value
                        )
                        return `${hours}`
                    } else {
                        return this.value
                    }
                },
            },
            offset: 10,
            gridLineColor: "#CFD8DC",
            tickPositions: [
                3600 * 0,
                3600 * 4,
                3600 * 8,
                3600 * 12,
                3600 * 16,
                3600 * 20,
                3600 * 24,
            ],
            resize: {
                enabled: false,
            },
            showLastLabel: true,
        },
        {
            title: {
                // text: "SPM",
                // text: "",
                // style: {
                //     color: "#1E88E5",
                //     fontWeight: "400",
                // },
                // offset: 30,
            },
            // opposite: false,
            // labels: {
            //     enabled: true,
            //     style: { color: "#1e88e5", fontWeight: "400" },
            //     align: "left",
            // },
            // offset: 10,
            // gridLineColor: "#CFD8DC",
            // tickPositions: [0, 4, 8, 12, 16, 20, 24],
        },
    ],
    tooltip: {
        backgroundColor: "#37474f",
        style: {
            color: "#fff",
        },
        formatter: function () {
            const toolTipArray = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { currentDataGrouping } = this.series as any
            const unitName = currentDataGrouping?.unitName

            let formatVariables: string
            switch (unitName) {
                case "day":
                    formatVariables = "%e %b %Y"
                    break
                case "week":
                    formatVariables = "%A, %e %b %Y"
                    break
                case "month":
                    formatVariables = "%B %Y"
                    break
                default:
                    formatVariables = "%e %b %Y"
                    break
            }

            const formattedDate = HighStock.dateFormat(
                formatVariables,
                this.x as number
            )
            toolTipArray.push(formattedDate)
            const points = this.points

            if (points) {
                points.forEach((point) => {
                    if (point.series.name === "Run Time") {
                        if (point.y || point.y === 0) {
                            const { hours, minutes } =
                                convertSecondsToHoursAndMinutes(point.y)

                            toolTipArray.push(
                                `Running Avg: ${hours}h ${minutes}m`
                            )
                        }
                    } else {
                        toolTipArray.push(`SPM: ${this.y}`)
                    }
                })
            }

            return toolTipArray
        },
    },
    xAxis: {
        type: "datetime",
        labels: {
            style: {
                color: "#37474f",
            },
        },
        lineColor: "#CFD8DC",
        tickLength: 0,
        minRange: 3 * 24 * 60 * 60 * 1000,
        /**
         * Note: this events field is configured in inital state of [options,setOptions]=useState() [./index.tsx]
         * noted here for so this behavior is not overlooked during debugging.
         * events: {
         *     afterSetExtremes: (event) =>
         *         setXaxisExtremes({ min: event.min, max: event.max }),
         * },
         */
    },
    // this tells highcharts to use local browser time instead
    time: {
        // this is the default
        // it tells highcharts to use UTC instead of local browser time
        // we want to use UTC because we apply timezones on the backend
        // and we want to display the data in the correct timezone
        useUTC: true,
    },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    plotOptions: {
        series: {
            boostThreshold: 100,
            marker: {
                enabled: true,
            },
        },
    },
    series: [
        {
            type: "line",
            data: [],
            name: "Run Time",
            yAxis: 0,
            color: "#FF9800",
            boostThreshold: 100,
            marker: {
                symbol: "circle",
                radius: 2,
            },
        },
        // {
        //     type: "line",
        //     data: [],
        //     name: "Spm",
        //     yAxis: 1,
        //     color: "#1e88e5",
        // },
    ],
    exporting: {
        csv: {
            dateFormat: "%m-%d-%Y",
        },
        enabled: true,
        buttons: {
            contextButton: {
                align: "right",
                verticalAlign: "top",
                y: -4,
                symbolStroke: "#1e88e5",
                menuItems: [
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                    "separator",
                    "printChart",
                    "viewFullscreen",
                ],
            },
        },
    },
}

export { wellDetailsChartOptions }
