import { FunctionComponent, ReactNode } from "react"
import "./wellIndexPage.module.scss"
import { useQuery } from "@apollo/client"
import { GET_INDEX_WELLS } from "../../graphql/queries/wells"
import {
    getMostRecentMidnightUnixMs_Chicago,
    getVariableDaysAgo_StartOfDayUnixMs,
} from "../../util/datesAndTimes/datesAndTimes"
import { WellIndexTableDataI } from "../../components/wellIndexTable/tableTypes"
import { formatWellsData } from "../../components/wellIndexTable/tableUtils"
import WellIndexTable from "../../components/wellIndexTable"
import styles from "./wellIndexPage.module.scss"
import { SyncLoader } from "react-spinners"
import EmptyState from "../../components/wellIndexTable/emptyState"

const WellIndexPage: FunctionComponent = () => {
    const { error, loading, data } = useQuery(GET_INDEX_WELLS, {
        variables: {
            first: 1000,
            last: null,
            before: null,
            after: null,
            equipStats_from: getVariableDaysAgo_StartOfDayUnixMs(8),
            equipStats_to: getMostRecentMidnightUnixMs_Chicago(),
        },
        fetchPolicy: "network-only",
    })

    let body: ReactNode

    if (error) {
        console.error("Error - Well index page:", error)
        body = <Error />
    }
    if (loading) {
        body = <Loading />
    }
    if (data) {
        const formattedData: WellIndexTableDataI[] = formatWellsData(data)
        if (formattedData.length === 0) {
            body = <EmptyState />
        } else {
            body = <WellIndexTable data={formattedData} />
        }
    }

    return <div className={styles.container}>{body}</div>
}

export default WellIndexPage

const Error: FunctionComponent = () => {
    return (
        <div className={styles.wellIndexError}>
            An error has occurred while loading your wells.
        </div>
    )
}

const Loading: FunctionComponent = () => {
    return (
        <div className={styles.wellIndexLoader}>
            <SyncLoader color="#1e88e5" size={13} />
        </div>
    )
}
