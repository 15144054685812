import { FunctionComponent, useEffect, useRef, useState } from "react"
import HighStock from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import styles from "./wellDetailsChart.module.scss"
import classNames from "classnames"
import { SyncLoader } from "react-spinners"
import Boost from "highcharts/modules/boost"
import { DataFrameFieldsFragment } from "../../../generated/graphql"
import { cloneDeep } from "lodash"
import {
    convertHighChartSeriesToCSV,
    convertToZipcelxFormat,
    createWellDetailsSearchParams,
    getAggregationUnits,
    isWellDetailsRangeValid,
    getDefaultMinMax,
    valuesInsideChartView,
    updateIncomingRunTimeForWellDetailsLineChart,
    getHighChartsDataInView,
} from "./wellDetailsChartUtils"
import Input from "../../shared/input"
import { wellDetailsChartOptions } from "./chartOptions"
import Exporting from "highcharts/modules/exporting"
import ExportData from "highcharts/modules/export-data"
import zipcelx from "zipcelx"
import { getVariableDaysAgo_StartOfDayUnixMs } from "../../../util/datesAndTimes/datesAndTimes"
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom"
import { StringStringObjectType } from "../../../types/commonTypes"
import { getURLParameters } from "../../../util/urlUtil"
import { DateIntervalType, WellDetailsUrlType } from "./wellDetailsChartType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import { IwpOutletContextI } from "../../../pages/individualWellPage"

Exporting(HighStock)
ExportData(HighStock)
Boost(HighStock)

export interface IwpLineChartI {
    runTimeData: DataFrameFieldsFragment | undefined
    spmData: DataFrameFieldsFragment | undefined
}

const IwpWellDetailsChart: FunctionComponent = () => {
    const iwpContext = useOutletContext()
    const {
        loading,
        error,
        wellDetails: { runTimeData, spmData },
    } = iwpContext as IwpOutletContextI

    /** URL Parameters */
    const [searchParams, setSearchParams] = useSearchParams()
    /** Date Interval  */
    const initialDateInterval = (searchParams.get("dateInterval") ||
        undefined) as DateIntervalType | null

    const [dateInterval, setDateInterval] = useState<DateIntervalType>(
        initialDateInterval || "day"
    )
    const [dataInView, setDataInView] = useState<boolean>(true)
    const chartReference = useRef<HighStock.Chart | null>(null)
    /** UseRef Hooks */
    const dateIntervalRef = useRef(initialDateInterval)
    const afterSetExtremeCount = useRef(0)

    const [options, setOptions] = useState<HighStock.Options>({
        ...wellDetailsChartOptions,
        xAxis: {
            ...wellDetailsChartOptions.xAxis,
            min: getVariableDaysAgo_StartOfDayUnixMs(31),
            events: {
                afterSetExtremes: function (this, event) {
                    const { min, max } = event
                    const isValueInView = valuesInsideChartView(
                        this.series[0].points
                    )
                    if (isValueInView) {
                        setDataInView(true)
                    } else {
                        setDataInView(false)
                    }

                    /**
                     *  if afterSetExtremeCount === 0, user has rendered the page via the url
                     *  if the user has used query parameters, the extremes will be updated in the load function
                     *  if there are no query parameters, query parameters will not be populated upon initial reneder.
                     *  once the user updates the chart via the web componet, parameters will be populated to the url.
                     */
                    if (afterSetExtremeCount.current > 0) {
                        // user is updating highchart from web
                        setSearchParams(
                            createWellDetailsSearchParams(
                                min,
                                max,
                                dateIntervalRef.current
                            ),
                            {
                                replace: true,
                            }
                        )
                        afterSetExtremeCount.current++
                        return
                    }
                    afterSetExtremeCount.current++
                },
            },
        },
    })

    /** Effects */
    useEffect(() => {
        if (options.series) {
            /**
             *
             *  High charts mutates data for performance reasons. To prevent mutations to orginal data array need to pass a copy
             *  https://github.com/highcharts/highcharts-react - see : why highcharts mutates my data
             *  https://github.com/highcharts/highcharts/issues/4259
             *
             * */
            const updatedRunTimeData =
                updateIncomingRunTimeForWellDetailsLineChart(
                    cloneDeep(runTimeData?.highchartSeries)
                )

            // is query done loading
            if (updatedRunTimeData && updatedRunTimeData.length > 0) {
                const spreadCopyOfXaxis = {
                    ...options.xAxis,
                } as HighStock.XAxisOptions

                const dataGroupingObj = {
                    enabled: true,
                    forced: true,
                    units: [["day", [1]]],
                } as HighStock.DataGroupingOptionsObject

                if (searchParams.size > 0) {
                    const searchParamFrom = searchParams.get("from")
                    const searchParamTo = searchParams.get("to")
                    const updatedURL: StringStringObjectType = {}

                    if (searchParamFrom || searchParamTo) {
                        // to & from in url query parameters
                        const wellDetailsUrlParameters = getURLParameters(
                            searchParams
                        ) as WellDetailsUrlType

                        let updatedRangeParameters = isWellDetailsRangeValid(
                            wellDetailsUrlParameters.from,
                            wellDetailsUrlParameters.to
                        )

                        if (!updatedRangeParameters) {
                            updatedRangeParameters = getDefaultMinMax(
                                runTimeData?.highchartSeries
                            )
                        }

                        updatedURL.from = updatedRangeParameters.from.toString()
                        updatedURL.to = updatedRangeParameters.to.toString()
                        spreadCopyOfXaxis.min = updatedRangeParameters.from
                        spreadCopyOfXaxis.max = updatedRangeParameters.to
                    }

                    if (searchParams.get("dateInterval")) {
                        const { selected, chartAggregationUnits } =
                            getAggregationUnits(
                                dateIntervalRef.current || "day"
                            )
                        dataGroupingObj.units = chartAggregationUnits
                        dateIntervalRef.current = selected
                        updatedURL.dateInterval = selected
                        setSearchParams(updatedURL, { replace: true })
                    }
                }

                const updateOptions: HighStock.Options = {
                    ...options,
                    series: [
                        {
                            type: "line",
                            data: updatedRunTimeData,
                            name: "Run Time",
                            yAxis: 0,
                            color: "#FF9800",
                        },
                        // {
                        //     type: "line",
                        //     data: cloneDeep(spmData?.highchartSeries) || [],
                        //     // data: [],
                        //     name: "Spm",
                        //     yAxis: 1,
                        //     color: "#1e88e5",
                        // },
                    ],
                    xAxis: spreadCopyOfXaxis,
                    plotOptions: {
                        ...options.plotOptions,
                        series: {
                            ...options.plotOptions?.series,
                            dataGrouping: dataGroupingObj,
                        },
                    },
                }

                setOptions(() => updateOptions)

                if (updatedRunTimeData) {
                    // eslint-disable-next-line @typescript-eslint/no-extra-semi
                    ;(function (HighStock) {
                        /**
                         * https://www.highcharts.com/docs/extending-highcharts/extending-highcharts
                         * update highcharts -  downloadXLS  method to use 3rd party library. See @https://api.highcharts.com/highcharts/exporting.csv
                         **/

                        HighStock.Chart.prototype.getCSV = function () {
                            const dataInView = getHighChartsDataInView(
                                this.series[0].points
                            )
                            return convertHighChartSeriesToCSV(dataInView)
                        }
                        HighStock.Chart.prototype.downloadXLS = function () {
                            const dataInView = getHighChartsDataInView(
                                this.series[0].points
                            )
                            return zipcelx({
                                filename: "chart",
                                sheet: convertToZipcelxFormat(dataInView),
                            })
                        }
                    })(HighStock)
                }
            }
        }
    }, [spmData, runTimeData])

    useEffect(() => {
        if (chartReference !== null) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const chart = (chartReference as any).current?.chart
            if (chart) {
                const points = chart.series[0].points as
                    | HighStock.Point[]
                    | undefined

                if (points) {
                    const isValueInView = valuesInsideChartView(points)
                    if (isValueInView) {
                        setDataInView(true)
                    } else {
                        setDataInView(false)
                    }
                }
            }
        }
    }, [options.plotOptions?.series?.dataGrouping?.units])

    if (error) return <IwpWellDetailsChartError />
    if (loading) return <IwpWellDetailsChartLoading />

    /** Handlers */
    const handleDateIntervalChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target
        if (options.series) {
            const { selected, chartAggregationUnits } = getAggregationUnits(
                value as DateIntervalType
            )

            const updateOptions: HighStock.Options = {
                ...options,
                plotOptions: {
                    ...options.plotOptions,
                    series: {
                        ...options.plotOptions?.series,
                        dataGrouping: {
                            enabled: true,
                            forced: true,
                            units: chartAggregationUnits,
                        },
                    },
                },
            }
            setOptions(updateOptions)
            const updatedParams = getURLParameters(searchParams)
            updatedParams.dateInterval = selected
            setSearchParams(updatedParams, {
                replace: true,
            })
            setDateInterval(selected)
            dateIntervalRef.current = selected
        }
    }

    let wellDetailsChartBody: React.ReactNode

    if (runTimeData && runTimeData.highchartSeries.length === 0) {
        wellDetailsChartBody = <IwpWellDetailsEmptyDataSet />
    } else {
        wellDetailsChartBody = (
            <>
                <div className={styles.container}>
                    <HighchartsReact
                        highcharts={HighStock}
                        constructorType={"stockChart"}
                        options={options}
                        ref={chartReference}
                    />
                    <div className={styles.dateIntervalContainer}>
                        <div className={styles.timezoneLabel}>
                            *Runtimes are displayed in Central Standard Time
                        </div>
                        <Input
                            type="select"
                            onChange={(e) => handleDateIntervalChange(e)}
                            orientation="horizontal"
                            disabled={false}
                            label="Date Interval"
                            options={[
                                { label: "Day", value: "day", disabled: false },
                                {
                                    label: "Week",
                                    value: "week",
                                    disabled: false,
                                },
                                {
                                    label: "Month",
                                    value: "month",
                                    disabled: false,
                                },
                            ]}
                            value={dateInterval}
                            inputCustomClass="well-details-chart-date-interval-input"
                            customLabelClass="well-details-chart-date-interval-label"
                        />
                    </div>
                </div>
                {!dataInView && (
                    <div className={styles.noDataInGraphViewWarning}>
                        <FontAwesomeIcon icon={faCircleInfo} />
                        &nbsp; No data was found for these graph settings.
                        Please adjust settings and try again.
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <Outlet />
            {wellDetailsChartBody}
        </>
    )
}

export default IwpWellDetailsChart

const IwpWellDetailsChartLoading = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <div className={styles.loadingText}> Loading </div>
            <SyncLoader color="#1e88e5" size={13} />
        </div>
    )
}

const IwpWellDetailsEmptyDataSet = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <Outlet />
            <div className={styles.emptyDataText}>
                There is currently no data found for this well. If data is
                expected, please reach out to{" "}
                <a href="mailto:support@petropower.com">
                    support@petropower.com
                </a>
            </div>
        </div>
    )
}

const IwpWellDetailsChartError = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <div className={styles.error}>Error</div>
        </div>
    )
}
