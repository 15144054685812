import { graphql } from "../../generated"

export const GET_INDEX_WELLS = graphql(`
    query GetIndexWells(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $equipStats_from: Long
        $equipStats_to: Long
    ) {
        wells(first: $first, last: $last, after: $after, before: $before) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
            }
            totalCount
            edges {
                node {
                    ...WellIdentificationFields
                    alert {
                        ...WellAlertFields
                    }
                    controlSetting {
                        ...ControlSettingFields
                    }
                    state {
                        ...WellControlStateFields
                    }
                    equipmentMetrics {
                        latestSpm {
                            ...DataPointFields
                        }
                        dailyRunTime {
                            ...DataFrameFields
                        }
                        windowedRunTime(
                            interval: {
                                from: { unixMilliseconds: $equipStats_from }
                                to: { unixMilliseconds: $equipStats_to }
                            }
                        ) {
                            ...DataFrameFields
                        }
                    }
                }
            }
        }
    }
`)
